import { FlexContainer } from "@/uikit/container";
import { Block } from "../base";
import { BlockContainer } from "../base/block-container";
import { RemoteImage } from "@/uikit/remote-image";
import { Typography } from "@/uikit/Typography";
import IconButton from "@/svg/components/iconButton";
import { blockWrapper, skeletonIconStyle, smallBlockTypographyWrapper, smallBlockWrapper } from "./secondInformation.css";
import Skills from "@/components/Skills";
import { Any } from "next-sanity";


export const SecondInformation = Block("secondInformation", ({ blog, slills, Socialls }) => {
    return (
        <BlockContainer blockPaddingTop="none" layout="centeredColumn" >
            <FlexContainer gap="40" justifyContent="center" alignItems="center" className={blockWrapper}>
                <FlexContainer alignItems="start" direction="column" className={smallBlockWrapper}>
                    {blog?.image && (
                        <RemoteImage
                            image={blog?.image}
                            height={126}
                            className={skeletonIconStyle}
                            sizes={{
                                mobile: "100%",
                                tablet: "495px",
                                desktop: "495px",
                            }}
                        />
                    )}
                    <FlexContainer direction="row" justifyContent="between" className={smallBlockTypographyWrapper}>
                        <FlexContainer direction="column" gap="small">
                            <Typography settings={{ tag: 'labelGrey', mode: 'grey' }}>{blog?.label}</Typography>
                            <Typography settings={{ tag: 'blockTitle', mode: 'black' }}>{blog?.title}</Typography>
                        </FlexContainer>
                        <IconButton />
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer>
                    {slills && (
                        <Skills slidesData={slills?.slides || [] as Any[]} label={slills?.label} title={slills?.title} />
                    )}
                </FlexContainer>
                <FlexContainer>
                    <FlexContainer alignItems="start" direction="column" className={smallBlockWrapper}>
                        {Socialls?.image && (
                            <RemoteImage
                                image={Socialls?.image}
                                height={126}
                                className={skeletonIconStyle}
                                sizes={{
                                    mobile: "100%",
                                    tablet: "495px",
                                    desktop: "495px",
                                }}
                            />
                        )}
                        <FlexContainer direction="row" justifyContent="between" className={smallBlockTypographyWrapper}>
                            <FlexContainer direction="column" gap="small">
                                <Typography settings={{ tag: 'labelGrey', mode: 'grey' }}>{Socialls?.label}</Typography>
                                <Typography settings={{ tag: 'blockTitle', mode: 'black' }}>{Socialls?.title}</Typography>
                            </FlexContainer>
                            <IconButton />
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </BlockContainer>
    )
});

export default SecondInformation;