import { SwiperSlide } from "swiper/react";
import { slideWrapper } from "./slideItem.css";
import { RemoteImage } from "@/uikit/remote-image";

interface SlideProps {
    image?: any;
    title?: string;
}

export const SlideItem: React.FC<SlideProps> = ({ image, title }) => {
    return (
        <div className={slideWrapper}>
            {image && (
                <RemoteImage
                    image={image}
                    height={100}
                    width={100}
                    sizes={{
                        mobile: "100%",
                        tablet: "495px",
                        desktop: "495px",
                    }}
                />
            )}
        </div>
    )
}

export default SlideItem;
