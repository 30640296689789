import { FlexContainer } from "@/uikit/container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "swiper/swiper-bundle.css";
import {
    slidesCard,
    slidesWrapper
} from "./carousel.css";
import SlideButton from "../SliderButton";
import SlideItem from "../SlideItem";

interface SkillsCarouselProps {
    slidesData?: {
        key: any;
        image: string;
        title: string;
    }[];
}
export const SkillsCarousel: React.FC<SkillsCarouselProps> = ({ slidesData }) => {

    return (
        <FlexContainer direction="column" className={slidesCard}>
            <FlexContainer direction="row" className={slidesWrapper} alignItems="start" justifyContent="between">
                <SlideButton direction={'prev'} className={'prevSlide'} />
                <Swiper
                    id="skills-carousel"
                    slidesPerView={3}
                    spaceBetween={50}
                    slidesPerGroup={1}
                    modules={[Navigation, Pagination, Autoplay, Virtual]}
                    navigation={{
                        nextEl: '.nextSlide',
                        prevEl: '.prevSlide',
                    }}
                    autoplay
                    loop
                    pagination={{
                        el: '.pagination',
                    }}
                    centeredSlides
                    grabCursor
                >
                    {slidesData && slidesData.map((slide, index) => (
                        <SwiperSlide key={slide.key | index}>
                            <SlideItem image={slide.image} title={slide.title} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SlideButton direction={'next'} className={'nextSlide'} />
            </FlexContainer>
        </FlexContainer>
    );
}

export default SkillsCarousel;
