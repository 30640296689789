import { FlexContainer } from "@/uikit/container"
import { blockWrapper } from "./skills.css";
import SkillsCarousel from "./Carousel";

interface SlideData {
    key: any;
    image: string;
    title: string;
}

interface SkillsProps {
    slidesData?: SlideData[];
    label?: string;
    title?: string;
}

export const Skills: React.FC<SkillsProps> = ({ slidesData, label, title }) => {
    return (
        <FlexContainer alignItems="start" direction="column" className={blockWrapper}>
            <SkillsCarousel slidesData={slidesData} />
            <p>Comp is work</p>
        </FlexContainer>
    )
}

export default Skills;